import React from 'react';
import PropTypes from 'prop-types';
import MDReactComponent from 'markdown-react-js';
import Instagram from 'components/Embeds/Instagram';

const Markdown = ({ convertLinksToEmbeds, ...rest }) => {
  function domain(url) {
    return url.replace('http://', '').replace('https://', '').split('/')[0];
  }

  function getYoutubeVideoData(link) {
    const youtubeLinkTypes = [
      'youtube.com/watch',
      'youtube.com/shorts',
      'youtu.be',
    ];

    const linkType = youtubeLinkTypes.find((type) => link.includes(type));
    if (!linkType) return null;

    let videoId = '';
    switch (linkType) {
      case 'youtube.com/watch':
        videoId = link.split('v=')[1];
        break;
      case 'youtube.com/shorts':
        videoId = link.split('shorts/')[1];
        break;
      case 'youtu.be':
        videoId = link.split('/').pop();
        break;
      default:
        break;
    }

    const aspectRatio = linkType === 'youtube.com/shorts' ? '0.56/1' : '16/9';
    const maxWidth = linkType === 'youtube.com/shorts' ? '500px' : '100%';

    return {
      embedUrl: `https://www.youtube.com/embed/${videoId}`,
      maxWidth,
      aspectRatio,
    };
  }

  function handleIterate(Tag, props, children) {
    let newProps = props;
    if (Tag === 'a') {
      const currentHost = domain(__SERVER__ ? '' : window.location.href);
      const matchedHost = domain(newProps.href);

      if (matchedHost !== '' && matchedHost !== currentHost) {
        newProps = {
          ...newProps,
          target: '_blank',
          rel: 'nofollow noopener noreferrer',
        };
      }
    }

    if (Tag === 'img') {
      newProps = {
        ...newProps,
        style: { maxWidth: '100%' },
      };
    }

    if (convertLinksToEmbeds) {
      if (
        Tag === 'a' &&
        (props.href.includes('youtube.com/watch') ||
          props.href.includes('youtube.com/shorts') ||
          props.href.includes('youtu.be'))
      ) {
        const youtubeData = getYoutubeVideoData(props.href);
        if (youtubeData) {
          const { embedUrl, maxWidth, aspectRatio } = youtubeData;
          return (
            <iframe
              width="100%"
              height="auto"
              style={{ aspectRatio: `${aspectRatio} auto`, maxWidth: maxWidth }}
              src={embedUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          );
        }
      }

      if (Tag === 'a' && props.href.includes('vimeo.com')) {
        const match = props.href.match(/vimeo\.com\/(\d+)/);

        if (match && match.length > 1 && match[1].length > 0) {
          const videoId = match[1];
          const embedUrl = `https://player.vimeo.com/video/${videoId}`;

          return (
            <iframe
              width="100%"
              height="auto"
              style={{ aspectRatio: '16/9 auto' }}
              src={embedUrl}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          );
        }
      }

      if (
        Tag === 'a' &&
        props.href.includes('soundcloud.com') &&
        !props.href.includes('on.soundcloud.com')
      ) {
        let trackUrl = encodeURIComponent(props.href);

        const embedUrl = `https://w.soundcloud.com/player/?url=${trackUrl}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`;

        return (
          <iframe
            width="100%"
            height="166"
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            src={embedUrl}
          ></iframe>
        );
      }

      if (
        Tag === 'a' &&
        (props.href.includes('instagram.com/p') ||
          props.href.includes('instagram.com/reel'))
      ) {
        return <Instagram postUrl={props.href} />;
      }

      if (Tag === 'a' && props.href.includes('tiktok.com')) {
        const tiktokVideoRegex =
          /^https?:\/\/(?:www\.)?tiktok\.com\/@[\w.]+\/video\/\d+/;

        if (tiktokVideoRegex.test(props.href)) {
          const videoId = props.href.split('video/')[1].split('/')[0];
          return (
            <>
              <blockquote
                className="tiktok-embed"
                cite={props.href}
                data-video-id={videoId}
                style={{
                  maxWidth: '500px',
                  width: '100%',
                  padding: 0,
                  margin: 0,
                  border: 'none',
                }}
              >
                <section></section>
              </blockquote>
              <script async src="https://www.tiktok.com/embed.js"></script>
            </>
          );
        }
      }

      if (Tag === 'a' && props.href.includes('behance.net/gallery')) {
        const postId = props.href
          .split('behance.net/gallery/')[1]
          .split('/')[0];

        const embedUrl = `https://www.behance.net/embed/project/${postId}?ilo0=1`;

        return (
          <iframe
            src={embedUrl}
            height="auto"
            width="100%"
            style={{ aspectRatio: '1.28/1 auto', maxWidth: '500px' }}
            allowFullScreen
            lazyload
            frameBorder="0"
            allow="clipboard-write"
            refererPolicy="strict-origin-when-cross-origin"
          ></iframe>
        );
      }
    }

    return <Tag {...newProps}>{children}</Tag>;
  }

  return (
    <MDReactComponent
      markdownOptions={{ linkify: true, typographer: true, breaks: true }}
      onIterate={handleIterate}
      {...rest}
    />
  );
};

Markdown.propTypes = {
  convertLinksToEmbeds: PropTypes.bool,
};

Markdown.defaultProps = {
  convertLinksToEmbeds: true,
};

export default Markdown;
